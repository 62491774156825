var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Job Information")]),_vm._v(" "),_c('p',{staticClass:"card-title-desc"},[_vm._v("\n                          Update Job Information\n                      ")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Job Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.job.job_name),expression:"job.job_name"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.job.job_name.$error,
                            },attrs:{"id":"job_name","type":"text","placeholder":"Enter Job Name"},domProps:{"value":(_vm.job.job_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.job, "job_name", $event.target.value)}}}),_vm._v(" "),(
                              _vm.submitted && !_vm.$v.job.job_name.required
                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                            This value is required.\n                          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Customer")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.job.customer_id),expression:"job.customer_id"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.job.customer_id.$error,
                            },attrs:{"id":"customer_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.job, "customer_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select a Customer")]),_vm._v(" "),_vm._l((_vm.customers),function(customer,id){return [_c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(customer))])]})],2),_vm._v(" "),(
                              _vm.submitted && !_vm.$v.job.customer_id.required
                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                            This value is required.\n                          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Start Date")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.job.start_date),expression:"job.start_date"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.job.start_date.$error,
                            },attrs:{"id":"start_date","type":"date","placeholder":"Enter Start Date"},domProps:{"value":(_vm.job.start_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.job, "start_date", $event.target.value)}}}),_vm._v(" "),(_vm.submitted && !_vm.$v.job.start_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                            This value is required.\n                          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("End Date")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.job.end_date),expression:"job.end_date"}],staticClass:"form-control",attrs:{"id":"end_date","type":"date","placeholder":"Enter End Date"},domProps:{"value":(_vm.job.end_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.job, "end_date", $event.target.value)}}})])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v("\n                        Save Changes\n                    ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":"/jobs","type":"reset"}},[_vm._v("\n                        Cancel\n                    ")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }