<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              CHECKOUT REGISTRATION FORM
              <div
                class="col-sm-2"
                style="display: inline-block; margin-left: 100px"
              >
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <i
                      class="bx bx-left-arrow-alt search-icon"
                      @click="previousMonth()"
                    ></i>
                    <input
                      type="text"
                      class="form-control"
                      v-model="currentActivedate"
                      readonly
                      style="
                        background-color: #fff;
                        padding-left: 0px;
                        text-align: center;
                        width: 190px;
                      "
                    />
                    <i
                      class="bx bx-right-arrow-alt search-icon"
                      @click="comingMonth()"
                      style="left: 165px"
                    ></i>
                  </div>
                </div>
              </div>
            </h4>

            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th style="width:120px;">Date</th>
                    <template v-for="(tt, index) in transactionTypes">
                      <th :key="index">{{ tt }}</th>
                    </template>
                    <th>Bank Report</th>
                    <th>Bank Difference</th>
                    <th>Amount To Safe</th>
                    <th>Amount From safe</th>
                    <th style="width: 40px; border: 0px">&nbsp;</th>
                    <th class="dailySales" style="text-align:right;">Daily Income</th>
                    <th>Diff</th>
                    <th style="width: 40px; border: 0px">&nbsp;</th>
                    <th class="BankDetails">Cash Balance</th>
                    <th class="BankDetails">Balance Safe</th>
                    <th class="BankDetails">Amount To Bank</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, date) in monthlyData" :key="date">
                    <td>{{ formateDate(date) }}</td>
                    <template v-for="(tt, index) in transactionTypes">
                      <td :key="index">
                        <span v-if="monthlyData[date] && monthlyData[date][tt]">
                          {{ formatPrice(monthlyData[date][tt]) }}
                        </span>
                      </td>
                    </template>
                    <td>
                      <input
                        class="form-control bankReport"
                        type="text"
                        @change="updateBankReport($event, date)"
                        v-model="monthlyData[date]['bank_report']"
                      />
                    </td>
                    <td>{{ monthlyData[date]["bank_diff"] }}</td>
                    <td>{{ monthlyData[date]["amount_to_safe"] }}</td>
                    <td>{{ monthlyData[date]["amount_from_safe"] }}</td>
                    <td style="width: 40px; border: 0px"></td>
                    <td class="dailySales"  style="text-align:right;">{{ getDailyIncome(date) }}</td>
                    <td>{{ monthlyData[date]["diff"] }}</td>
                    <td style="width: 40px; border: 0px"></td>
                    <td class="BankDetails">
                      {{ monthlyData[date]["cash_balance"] }}
                    </td>
                    <td class="BankDetails">{{ monthlyData[date]["safe_balance"] }}</td>
                    <td class="BankDetails">
                      <input
                        class="form-control bankReport"
                        type="text"
                        @change="updateAmountToBank($event, date)"
                        v-model="monthlyData[date]['amount_to_bank']"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <template v-for="(row, index) in totalDetails">
                      <td :key="index" style="font-weight: 600">
                        {{ row["amount"] }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.dailySales {
  background-color: #00ff01;
}
.BankDetails {
  background-color: #ffff00;
}
td{ text-align: right !important;}
</style>
<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import simplebar from "simplebar-vue";

export default {
  components: { Layout, PageHeader, simplebar },
  data() {
    return {
      currentActiveMonth: 0,
      tblRows: [],
      transactionTypes: [],
      title: "DASHBOARD",
      items: [
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "DASHBOARD",
          active: true,
        },
      ],
      monthlyData: [],
      startOfMonth: "",
      reqStartMonth: "",
      reqEndMonth: "",
      bankReports: [],
      diffArr: [],
      cashBalance: [],
      dailyincome: [],
      safeBalance: [],
      amountToBank: [],
      totalDetails: [],
      endOfMonth: "",
    };
  },
  methods: {
    updateBankReport(e, date) {
      let amount = e.target.value;
      let data = {
        date: date,
        amount: amount,
      };
      this.monthlyData[date]["bank_report"] = amount;
      this.monthlyData[date]["bank_diff"] = parseFloat(
        this.monthlyData[date]["Swipe Receipts"]
      ).toFixed(2);
      this.monthlyData[date]["bank_diff"] -= parseFloat(
        this.monthlyData[date]["bank_report"]
      ).toFixed(2);
      this.monthlyData[date]["bank_diff"] = parseFloat(
        this.monthlyData[date]["bank_diff"]
      ).toFixed(2);

      axios.post(`/api/save_bank_report_value`, data).then((response) => {});
    },
    updateAmountToBank(e, date) {
      let amount = e.target.value;
      let data = {
        date: date,
        amount: amount,
      };
      this.monthlyData[date]["amount_to_bank"] = amount;
      axios.post(`/api/save_amount_to_bank`, data).then((response) => {
        this.get_monthly_balance();
      });
    },

    //cash_balance  +  amount_to_safe - amount_from_safe + cash_receipt  -  cash_expense
    getCashBalance(date) {
      this.monthlyData[date]["cash_balance"]   = this.cashBalance[date];
      this.monthlyData[date]["safe_balance"]   = this.safeBalance[date];
      this.monthlyData[date]["diff"]           = this.diffArr[date];
      this.monthlyData[date]["amount_to_bank"] = this.amountToBank[date];
      
      
      this.monthlyData[date]["bank_report"] = this.bankReports[date];
      if (this.monthlyData[date]["bank_report"] == undefined) {
        this.monthlyData[date]["bank_report"] = 0;
      }
      this.monthlyData[date]["bank_diff"] = parseFloat(
        this.monthlyData[date]["Swipe Receipts"]
      ).toFixed(2);
      this.monthlyData[date]["bank_diff"] -= parseFloat(
        this.monthlyData[date]["bank_report"]
      ).toFixed(2);
      this.monthlyData[date]["bank_diff"] = parseFloat(
        this.monthlyData[date]["bank_diff"]
      ).toFixed(2);
    },
    getDailyIncome(date) {
      let dailyIncome = 0;
      dailyIncome += parseFloat(this.monthlyData[date]["Cash Receipts"]);
      dailyIncome += parseFloat(this.monthlyData[date]["Swipe Receipts"]);
      //dailyIncome -= parseFloat(this.monthlyData[date]["amount_to_safe"]);
      return parseFloat(dailyIncome).toFixed(2);
    },
    formatPrice(price) {
      return price.toLocaleString("en-US");
    },
    formateDate(date_vlaue) {
      var date = moment(date_vlaue, "YYYY-MM-DD");
      let date_formated = date.format("DD-MM-YYYY");

      return date_formated;
    },
    previousMonth() {
      this.currentActiveMonth = this.currentActiveMonth + 1;
      this.monthDates();
      this.get_monthly_balance();
    },
    comingMonth() {
      this.currentActiveMonth = this.currentActiveMonth - 1;
      this.monthDates();
      this.get_monthly_balance();
    },
    monthDates() {
      var currentDate = moment().subtract(this.currentActiveMonth, "months");
      const startOfMonth = currentDate.clone().startOf("month");
      const endOfMonth = currentDate.clone().endOf("month");

      this.startOfMonth = startOfMonth.format("DD-MM-YY");
      this.endOfMonth = endOfMonth.format("DD-MM-YY");

      this.reqStartMonth = startOfMonth.clone().format("YYYY-MM-DD");
      this.reqEndMonth = endOfMonth.clone().format("YYYY-MM-DD");

      let j = currentDate.daysInMonth();
      this.tblRows = [];
      for (var i = 0; i < j; i++) {
        this.tblRows.push(
          moment(startOfMonth).add(i, "days").format("YYYY-MM-DD")
        );
        //this.tempDatesRange.push(moment(startOfMonth).add(i, 'days').format("yyyy-M-DD"));
      }
      this.currentActivedate =
        moment(startOfMonth).format("MMM") +
        " " +
        moment(startOfMonth).format("DD") +
        " - " +
        moment(endOfMonth).format("DD") +
        " , " +
        moment(endOfMonth).format("YYYY");
    },
    get_monthly_balance() {
      let data = {
        start_date: this.reqStartMonth,
        end_date: this.reqEndMonth,
      };
      axios.post(`/api/get_monthly_data`, data).then((response) => {
        this.monthlyData = response.data.dailySales;
        this.cashBalance = response.data.cashBalance;
        this.safeBalance = response.data.safeBalance;
        this.amountToBank = response.data.amountToBank;
        this.bankReports = response.data.bankReports;
        this.diffArr = response.data.diffArr;
        this.totalDetails = response.data.totalDetails;
        console.log(this.monthlyData);
        for (const [key, value] of Object.entries(this.monthlyData)) {
          this.getCashBalance(key);
        }
      });
    },
    async getTransactionTypes() {
      axios.get(`/api/get/transaction_types`).then((response) => {
        this.transactionTypes = response.data;
      });
    },
  },
  mounted() {},
  created() {
    this.monthDates();
    this.getTransactionTypes();
    this.get_monthly_balance();
  },
};
</script>
<style scoped>
.bankReport {
  height: 35px;
  width: 145px;
}
</style>