<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import pagination from 'laravel-vue-pagination'
/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader , pagination },
  data() {
    return {
      TransData: {
                type:Object,
                default:null
            },
      title: "Manage Transaction Types",
      search:"",
      editID:'',
      deleteArr: [],
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Transaction Types",
          active: true
        }
      ],
      showModal: false,
      showUpdateModal:false,
      submitted: false,
      transactionType: {
        name: "",
        status: "Active",
      },
    };
  },
  validations: {
    transactionType: {
      name: { required }
    },
  },
  methods: {
    edit_transaction_type(data){
      this.editID = data.id;
      this.transactionType.name = data.name;
      this.transactionType.status = data.status;
      this.showUpdateModal = true;
    
    },
    
    async getResults(page = 1) {
      axios
        .get(`/api/transaction_types?page=${page}&search=${this.search}&type=mechanic`)
        .then(({ data }) => {this.TransData = data;});
    },
    closeUpdateModal(e){
            this.editID = '';
            this.transactionType.name = '';
            this.transactionType.status = '';
            this.showUpdateModal = false;
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleUpdate(e){
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('first ');
        return;
      } else {
        axios
          .post("/api/update/transaction_type/" + this.editID, this.transactionType)
          .then(response => {
            this.$swal(response.data.message);
            this.editID = '';
            this.transactionType.name = '';
            this.transactionType.status = '';
            this.showUpdateModal = false;

            this.getResults();
          })
          .catch(err => {
              if (err.response.status === 422) {
                  this.errors = [];
                  _.each(err.response.data.errors, error => {
                      _.each(error, e => {
                          this.errors.push(e);
                      });
                  });
              }
          });        
      }        
    },
    handleSubmit(e) {
      console.log('form submited');
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('first ');
        return;
      } else {
        this.showModal = false;
        axios
          .post("/api/save/transaction_type", this.transactionType)
          .then(response => {
              this.showForm = false;
              this.getResults();              
              this.transactionType = {
                        name: "",
                        status: "Active"
                      };

          })
          .catch(err => {
              if (err.response.status === 422) {
                  this.errors = [];
                  _.each(err.response.data.errors, error => {
                      _.each(error, e => {
                          this.errors.push(e);
                      });
                  });
              }
          });        
      }
      this.submitted = false;
    },
  },
  mounted() {
     this.getResults();
     
  },
  created() {
    //this.getResults();
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-on:keyup.enter="getResults()" v-model="search" placeholder="Search..." />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <button
                    type="button"
                    class="btn btn-success btn-rounded mb-2 me-2"
                     @click="showModal = true"
                  >
                    <i class="mdi mdi-plus me-1"></i> Add Transaction Type
                  </button>
                    <b-modal
                    v-model="showModal"
                    title="Add New Transaction Type"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                  <form @submit.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="name">Transaction Type Name</label>
                            <input
                              id="name"
                              v-model="transactionType.name"
                              type="text"
                              class="form-control"
                              placeholder="Insert Transaction Type"
                              :class="{
                                'is-invalid':
                                  submitted && $v.transactionType.name.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && !$v.transactionType.name.required
                              "
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="showModal = false">Close</b-button>
                        <b-button type="submit" variant="success" class="ms-1"
                          >Add New Transaction Type</b-button
                        >
                      </div>
                    </form>
                  </b-modal>

                    <b-modal
                    v-model="showUpdateModal"
                    title="Update Transaction Type"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                  <form @submit.prevent="handleUpdate">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="name">Transaction Type Name</label>
                            <input
                              id="name"
                              v-model="transactionType.name"
                              type="text"
                              class="form-control"
                              placeholder="Insert Transaction Type"
                              :class="{
                                'is-invalid':
                                  submitted && $v.transactionType.name.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && !$v.transactionType.name.required
                              "
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="mb-3">
                            <label for="name">Transaction Type Status</label>
                            <select v-model="transactionType.status" class="form-control">
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                            <div
                              v-if="
                                submitted && !$v.transactionType.status.required
                              "
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="closeUpdateModal()">Close</b-button>
                        <b-button type="submit" variant="success" class="ms-1"
                          >Update Transaction Type</b-button
                        >
                      </div>
                    </form>
                  </b-modal>

                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tt in TransData.data" :key="tt.id">
                    <td>
                     <div class="form-check font-size-16">
                        {{ tt.id }}
                      </div>
                    </td>
                    <td>{{tt.name}}</td>
                    <td>
                      {{tt.status}}            
                    </td>
                    <td>
                        <a @click="edit_transaction_type(tt)" class="mr-3">
                          <i class="fas fa-pencil-alt text-success me-1"></i> Update
                        </a> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination align="center" :data="TransData" @pagination-change-page="getResults"></pagination>

          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<style>
  a{
    cursor: pointer;
  }
</style>