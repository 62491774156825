<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">CHECKOUT REGISTRATION FORM</h4>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>S.no #</th>
                    <th>Date</th>
                    <th>Transaction Description</th>
                    <th>Cash Expenses</th>
                    <th>Cash Receipts</th>
                    <th>Balance</th>
                    <th>Swipe Expenses</th>
                    <th>Swipe Receipts</th>
                    <th>Mechanic</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import simplebar from "simplebar-vue";

export default {
  components: { Layout, PageHeader, simplebar },
  data() {
    return {
      openingbalance: [],
      styleObject: {
        top: "",
        left: "",
      },
      title: "DASHBOARD",
      items: [
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "DASHBOARD",
          active: true,
        },
      ],
    };
  },
  methods: {
    async get_opening_balance() {
      axios.get(`/api/opening_balance`).then((response) => {
        this.openingbalance = response.data;
      });
      console.log(this.openingbalance);
    },
  },
  mounted() {
    this.get_opening_balance();
  },
  created() {},
};
</script>