<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";

/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Tasks",
      notesArray: [],
      imageArray: [],
      notes: "",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Tasks",
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      employees: [],
      task: {
        task_name: "",
        task_description: "",
        due_date: "",
        assigned_to: "",
        time_in_hours: "",
        time_minutes: "",
        time_period: "",
        status:"",
      },
      timeHours: [
        {
          label: "7",
          index: "AM",
        },
        {
          label: "8",
          index: "AM",
        },
        {
          label: "9",
          index: "AM",
        },
        {
          label: "10",
          index: "AM",
        },
        {
          label: "11",
          index: "AM",
        },
        {
          label: "12",
          index: "PM",
        },
        {
          label: "1",
          index: "PM",
        },
        {
          label: "2",
          index: "PM",
        },
        {
          label: "3",
          index: "PM",
        },
        {
          label: "4",
          index: "PM",
        },
        {
          label: "5",
          index: "PM",
        },
        {
          label: "6",
          index: "PM",
        },
      ],
      timeMinutes: [
        {
          label: "00",
        },
        {
          label: "15",
        },
        {
          label: "30",
        },
        {
          label: "45",
        },
      ],
      multipleFiles: [],

      status: [
        {
          label: "Open",
        },
        {
          label: "Closed",
        },
        {
          label: "Completed",
        },
      ],
    };
  },
  validations: {
    task: {
      task_name: { required },
      task_description: { required },
      due_date: { required },
      assigned_to: { required },
      time_in_hours: { required },
      time_in_minutes: { required },
    },
  },
  methods: {
     imagePreview(image){
window.open(image, "_blank"); 
     },
    async getEmployees() {
      axios.get(`/api/get/employees`).then((response) => {
        this.employees = response.data;
      });
    },
    getResults() {
      axios.get(`/api/task/${this.$route.params.id}`).then(({ data }) => {
        this.task = data.data;
        this.notesArray = data.notes;
        this.imageArray = data.taskImages;
        console.log(this.imageArray);
      });
    },
    handleFileUpload(){
        this.multipleFiles = this.$refs.file.files;
      
        console.log(this.multipleFiles);
      },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("first ");
        return;
      } else {
        this.showModal = false;
        let formData = new FormData();
        for (const i of Object.keys(this.multipleFiles)) {
            formData.append('file[]', this.multipleFiles[i])
          }
          formData.append('task_name', this.task.task_name);
        formData.append('task_description', this.task.task_description);
        formData.append('due_date', this.task.due_date);
        formData.append('assigned_to', this.task.assigned_to);
        formData.append('time_in_hours', this.task.time_in_hours);
        formData.append('time_in_minutes', this.task.time_in_minutes);
        formData.append('status', this.task.status);
        
        axios
          .post("/api/update/task/" + this.$route.params.id, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              })
          .then((response) => {
            console.log(response);
              // this.imageArray = response.data.taskImages;
            window.location.reload();
          });      
          }
    },
    createItem: function () {
      if (this.notes == "") {
        alert("Please Fill The Notes details!");
        return;
      }
      let data = {
        notes: this.notes,
        task_id: this.$route.params.id,
        user: this.$gate.user.name,
      };

      axios.post("/api/save-notes", data).then((response) => {
        this.getResults();
        this.notes = "";
      });
    },
  },
  mounted() {
    console.log(this.$route.params.id);
  },
  created() {
    this.getEmployees();

    this.getResults();
  },
};
</script>
<style scoped>
.notes-section {
  border: 1px solid #cbcaca;
  padding: 12px;
  box-shadow: 1px 0px 5px 1px #e5dfdf;
  margin-bottom: 9px;
}
.notes-list {
  list-style-type: none;
  padding-left: 0px;
}
.notes-author {
  color: #a1a3a5;
}
.image-preview{
  padding-left:5px
}
.image-preview:hover{
  -ms-transform: scale(2); /* IE 9 */
  -webkit-transform: scale(2); /* Safari 3-8 */
  transform: scale(2);
  transition: transform .5s ease;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-5">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic Information</h4>
            <p class="card-title-desc">Fill all information below</p>
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="task_name">Task Name</label>
                    <input
                      id="task_name"
                      v-model="task.task_name"
                      type="text"
                      class="form-control"
                      placeholder="Task name"
                      :class="{
                        'is-invalid': submitted && $v.task.task_name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.task.task_name.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label for="task_description">Task Description</label>
                    <textarea rows="9"
                      id="task_description"
                      v-model="task.task_description"
                      class="form-control"
                      placeholder="Task description"
                      :class="{
                        'is-invalid':
                          submitted && $v.task.task_description.$error,
                      }"
                    ></textarea>
                    <div
                      v-if="submitted && !$v.task.task_description.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="due_date">Due Date</label>
                    <input
                      id="due_date"
                      v-model="task.due_date"
                      type="date"
                      class="form-control"
                      placeholder="Due Date"
                      :class="{
                        'is-invalid': submitted && $v.task.due_date.$error,
                      }"
                    />
                    <div
                      v-if="submitted && !$v.task.due_date.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-3">
                    <label for="time_in_hours">Time hrs</label>
                    <select
                      class="form-select time_in_hours"
                      v-model="task.time_in_hours"
                    >
                      <template v-for="(item, index) in timeHours">
                        <option :value="item.label" :key="index">
                          {{ item.label + " " + item.index }}
                        </option>
                      </template>
                    </select>
                    <div
                      v-if="submitted && !$v.task.time_in_hours.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-3">
                    <label for="time_in_minutes">Time min.</label>
                    <select
                      class="form-select time_in_minutes"
                      v-model="task.time_in_minutes"
                    >
                      <template v-for="(item, index) in timeMinutes">
                        <option :value="item.label" :key="index">
                          {{ item.label }}
                        </option>
                      </template>
                    </select>
                    <div
                      v-if="submitted && !$v.task.time_in_minutes.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="email">Assign To</label>
                    <select
                      v-model="task.assigned_to"
                      class="form-control"
                      placeholder="Select Employee"
                      :class="{
                        'is-invalid': submitted && $v.task.assigned_to.$error,
                      }"
                    >
                      <option value="">Select Option</option>
                      <template v-for="(m, index) in employees">
                        <option :value="m" :key="index">{{ m }}</option>
                      </template>
                    </select>
                    <div
                      v-if="submitted && !$v.task.assigned_to.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                   <div class="col-6" v-if="$gate.isAdmin()">
                  <div class="mb-3">
                    <label for="email">Status</label>
                    <select
                      v-model="task.status"
                      class="form-control"
                      placeholder="Select Employee"
                    >
                      <option value="">Select Option</option>
                      <template v-for="(m, index) in status">
                        <option :value="m.label" :key="index">{{ m.label }}</option>
                      </template>
                    </select>
                    <div
                      v-if="submitted && !$v.task.assigned_to.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-6" v-else>
                  <div class="mb-3">
                    <label for="email">Status</label>
                    <select
                      v-model="task.status"
                      class="form-control"
                      placeholder="Select Employee"
                    >
                      <option value="">Select Option</option>
                      <template v-for="(m, index) in status">
                        <option :value="m.label" :key="index" v-if="m.label!='Closed'">{{ m.label }}</option>
                      </template>
                    </select>
                    <div
                      v-if="submitted && !$v.task.assigned_to.required"
                      class="invalid-feedback"
                    >
                      This value is required.
                    </div>
                  </div>
                </div>
                <div class="col-6 text-start  mt-3">
                   <div class="mb-3">
                       <label for="email">Images Upload</label>
                           <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" multiple/>
                        </div>
                        </div>
                <div class="col-12 mb-4">
                  <span  v-for="(image, index) in imageArray"
                    :key="index" style="">
                    <img
                      :src="image"
                      width="85"
                      alt="Card image"
                      class="img-fluid "
                      @click="imagePreview(image)"
                    />
                  </span>
                </div>
               
              </div>

              <button type="submit" class="btn btn-primary mr-1">
                Update Task
              </button>
              <router-link
                v-if="task"
                to="/tasks"
                type="reset"
                class="btn btn-secondary"
              >
                Cancel
              </router-link>
            </form>
          </div>
        </div>
      </div>
      <div class="col-7">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <h4 class="card-title">Notes</h4>
              <div class="notes">
                <ul class="notes-list">
                  <li
                    class="notes-section"
                    v-for="(notes, index) in notesArray"
                    :key="index"
                  >
                    <div class="notes-details">
                      {{ notes.note }}
                    </div>
                    <div class="notes-author text-end">
                      {{ notes.user }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="table-responsive edit-task">
                <table class="table align-middle mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <textarea
                          id="notes"
                          cols=""
                          v-model="notes"
                          class="form-control"
                          placeholder="Enter Notes"
                        ></textarea>
                      </td>
                      <td>
                        <a href="javascript:void(0)" @click="createItem()"
                          ><i
                            id="saveItem"
                            class="bx bx-save text-success me-1 fs-3 lh-2"
                          ></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
