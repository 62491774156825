var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Sale Information")]),_vm._v(" "),_c('p',{staticClass:"card-title-desc"},[_vm._v("\n                          Fill all information below\n                      ")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Sale Date")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dailySale.date),expression:"dailySale.date"}],staticClass:"form-control",attrs:{"readonly":"","type":"date","placeholder":"Select Sale Date"},domProps:{"value":(_vm.dailySale.date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dailySale, "date", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Transaction Description")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dailySale.description),expression:"dailySale.description"}],staticClass:"form-control",class:{
                                      'is-invalid':
                                        _vm.submitted && _vm.$v.dailySale.description.$error,
                                    },attrs:{"type":"text","placeholder":"Enter Description"},domProps:{"value":(_vm.dailySale.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dailySale, "description", $event.target.value)}}}),_vm._v(" "),(
                                      _vm.submitted && !_vm.$v.dailySale.description.required
                                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                    This value is required.\n                                  ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"phone_number"}},[_vm._v("Transaction Type")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dailySale.transaction_type),expression:"dailySale.transaction_type"}],staticClass:"form-control",class:{
                                      'is-invalid':
                                        _vm.submitted && _vm.$v.dailySale.transaction_type.$error,
                                    },attrs:{"placeholder":"Enter Transaction Type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dailySale, "transaction_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Transaction Type")]),_vm._v(" "),_vm._l((_vm.transactionTypes),function(tt,index){return [_c('option',{key:index,domProps:{"value":tt}},[_vm._v(_vm._s(tt))])]})],2),_vm._v(" "),(_vm.submitted && !_vm.$v.dailySale.amount.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                    This value is required.\n                                  ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Amount")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dailySale.amount),expression:"dailySale.amount"}],staticClass:"form-control",class:{
                                      'is-invalid':
                                        _vm.submitted && _vm.$v.dailySale.amount.$error,
                                    },attrs:{"id":"amount","type":"text","size":"any","placeholder":"Enter Amount"},domProps:{"value":(_vm.dailySale.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dailySale, "amount", $event.target.value)}}}),_vm._v(" "),(_vm.submitted && !_vm.$v.dailySale.amount.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                    This value is required.\n                                  ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Employee")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dailySale.employee),expression:"dailySale.employee"}],staticClass:"form-control",class:{
                                      'is-invalid':
                                        _vm.submitted && _vm.$v.dailySale.employee.$error,
                                    },attrs:{"placeholder":"Select Employee"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dailySale, "employee", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Option")]),_vm._v(" "),_vm._l((_vm.employees),function(m,index){return [_c('option',{key:index,domProps:{"value":m}},[_vm._v(_vm._s(m))])]})],2),_vm._v(" "),(_vm.submitted && !_vm.$v.dailySale.employee.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                                    This value is required.\n                                  ")]):_vm._e()])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v("\n                              Save Changes\n                          ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }