import Vue from "vue";
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("home", require("./home").default);

Vue.component("login", require("./account/login").default);
Vue.component("register", require("./account/register").default);
Vue.component("forgot-password", require("./account/forgot-password").default);
Vue.component("reset-password", require("./account/reset-password").default);

Vue.component("layout-horizontal", require("./layout/horizontal").default);

// Vue.component("pages-starter", require("./utility/starter").default);
// Vue.component("pages-maintenance", require("./utility/maintenance").default);
// Vue.component("pages-coming-soon", require("./utility/coming-soon").default);
// Vue.component("pages-timeline", require("./utility/timeline").default);
// Vue.component("pages-faqs", require("./utility/faqs").default);
// Vue.component("pages-pricing", require("./utility/pricing").default);
// Vue.component("pages-404", require("./utility/404").default);
// Vue.component("pages-500", require("./utility/500").default);

