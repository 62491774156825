<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Sales",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Sales",
          active: true
        }
      ],
      showModal: false,
      submitted: false,
      employees: [],
      currentLedgerDate: moment().format("YYYY-MM-DD"),
      transactionTypes: [],
      initialValue:'0.00',
      dailySale: {
        date: "",
        description: "",
        transaction_type: "",
        amount: "",
        employee: "",
      },
    };
  },
  validations: {
    dailySale: {
      description: { required },
      transaction_type: { required },
      amount: { required },
      employee: { required }
    },
  },
  methods: {
    getResults() {
      axios
        .get(`/api/sale/${this.$route.params.id}`)
        .then(({ data }) => {
          this.dailySale = data.data;
          this.initialValue = this.dailySale.amount;
          console.log(this.dailySale);
    });
    },
    async getEmployees() {
      axios.get(`/api/get/employees`).then((response) => {
        this.employees = response.data;
      });
    },
    
    async getTransactionTypes() {
      axios.get(`/api/get/transaction_types`).then((response) => {
        this.transactionTypes = response.data;
      });
    },
    addActivityLog(value,type){
     
      let data = {
        inputFieldValue: value.description,
        initialValue :  this.initialValue,
        updatedValue :  value.amount,
        user: window.user,
        date:this.currentLedgerDate,
        type:type,
      };
       axios.post("/api/save-acivity-log", data).then((response) => {
        this.get_log();
      });
    },
    /**
     * Modal form submit
     */
    //eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('first ');
        console.log(this.$v);
        return;
      } else {
        this.addActivityLog(this.dailySale,'updated_sale');
        this.showModal = false;
       
        axios
          .post("/api/update/sale/" + this.$route.params.id, this.dailySale)
          .then(response => {
            this.$swal(response.data.message);
            this.$router.push({ name: 'dashboard1' })
          })
          .catch(err => {
              if (err.response.status === 422) {
                  this.errors = [];
                  _.each(err.response.data.errors, error => {
                      _.each(error, e => {
                          this.errors.push(e);
                      });
                  });
              }
          });        
      }
    },
    
  },
  mounted() {
    console.log(this.$route.params.id);
},
  created() {
    this.getEmployees();
    this.getTransactionTypes();
    this.getResults();
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Sale Information</h4>
                        <p class="card-title-desc">
                            Fill all information below
                        </p>

                        <!-- THIS SECTION IS FOR ERRORS THAT WOULD COME FROM API -->
  
                        <form @submit.prevent="handleSubmit">
                            <div class="row">
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label for="name">Sale Date</label>
                                    <input
                                      readonly
                                      v-model="dailySale.date"
                                      type="date"
                                      class="form-control"
                                      placeholder="Select Sale Date"
                                    />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label for="name">Transaction Description</label>
                                    <input
                                      v-model="dailySale.description"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Description"
                                      :class="{
                                        'is-invalid':
                                          submitted && $v.dailySale.description.$error,
                                      }"
                                    />
                                    <div
                                      v-if="
                                        submitted && !$v.dailySale.description.required
                                      "
                                      class="invalid-feedback"
                                    >
                                      This value is required.
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label for="phone_number">Transaction Type</label>
                                    <select
                                      class="form-control"
                                      v-model="dailySale.transaction_type"
                                      placeholder="Enter Transaction Type"
                                      :class="{
                                        'is-invalid':
                                          submitted && $v.dailySale.transaction_type.$error,
                                      }"
                                    >
                                      <option value="">Transaction Type</option>
                                      <template v-for="(tt, index) in transactionTypes">
                                        <option :value="tt" :key="index">{{ tt }}</option>
                                      </template>
                                    </select>
                                    <div
                                      v-if="submitted && !$v.dailySale.amount.required"
                                      class="invalid-feedback"
                                    >
                                      This value is required.
                                    </div>

                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label for="email">Amount</label>
                                    <input
                                      id="amount"
                                      v-model="dailySale.amount"
                                      type="text"
                                      size="any"
                                      class="form-control"
                                      placeholder="Enter Amount"
                                      :class="{
                                        'is-invalid':
                                          submitted && $v.dailySale.amount.$error,
                                      }"
                                    />
                                    <div
                                      v-if="submitted && !$v.dailySale.amount.required"
                                      class="invalid-feedback"
                                    >
                                      This value is required.
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label for="email">Employee</label>
                                    <select
                                      v-model="dailySale.employee"
                                      class="form-control"
                                      placeholder="Select Employee"
                                      :class="{
                                        'is-invalid':
                                          submitted && $v.dailySale.employee.$error,
                                      }"
                                    >
                                      <option value="">Select Option</option>
                                      <template v-for="(m, index) in employees">
                                        <option :value="m" :key="index">{{ m }}</option>
                                      </template>
                                    </select>
                                    <div
                                      v-if="submitted && !$v.dailySale.employee.required"
                                      class="invalid-feedback"
                                    >
                                      This value is required.
                                    </div>
                                  </div>
                                </div>



                            </div>

                            <button type="submit"  class="btn btn-primary mr-1">
                                Save Changes
                            </button>
                        </form>
                    </div>
                </div>


            </div>
        </div>
  </Layout>
</template>
