<script>
import MetisMenu from "metismenujs/dist/metismenujs";

import { menuItems } from "./menu";

/**
 * Side-nav component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
      manage_users:false,
      manage_jobs:false,
      manage_scheduler:false,
      manage_dashboard:false
    };
  },
  mounted: function () {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    // console.log(this.$route);

    if(this.$route.name.includes('dashboard') || this.$route.name.includes('dashboard1')){
    
      this.manage_dashboard = true;
    }
    if(this.$route.name.includes('job-scheduler')){
      this.manage_scheduler = true;
    }
    if(this.$route.name.includes('job')){
      this.manage_jobs = true;
    }
    if(this.$route.name.includes('user') || 
        this.$route.name.includes('administrator') ||
          this.$route.name.includes('customer') ){
      this.manage_users = true;
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
  },
};
</script>
<style scoped>
.router-link-active{
  color:#fff !important;
}
</style>
<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
 <!-- <li :class="{'mm-active':manage_dashboard}">
          <a href="javascript:void(0);" class="" >
            <i class="bx bx-home-circle"></i> <span>Dashboard</span></a>  
        </li>  -->
 <!-- <li :class="{'mm-active':manage_dashboard}">
         <router-link to="/">Dashboard</router-link>
        </li>  -->
        <li :class="{'mm-active':manage_dashboard}">
          <a href="javascript:void(0);" class="is-parent has-dropdown" >
            <i class="bx bx-user"></i> <span>Dashboard</span></a>  
          <ul  class="sub-menu" style="" :class="{'mm-show':manage_dashboard}">
            <li>
               <router-link to="/">Dashboard</router-link>
            </li>
          </ul>
        </li>  
        <li :class="{'mm-active':manage_users}">
          <a href="javascript:void(0);" class="is-parent has-dropdown" >
            <i class="bx bx-user"></i> <span>Manage Users</span></a>  
          <ul  class="sub-menu" style="" :class="{'mm-show':manage_users}">
            <li>
              <router-link to="/users">Workers</router-link>
            </li>
          </ul>
        </li>      
      

    </ul>
  </div>
  <!-- Sidebar -->
</template>
