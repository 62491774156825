export default [
    { path: '/',name:'dashboard1', component: require('./views/Dashboard.vue').default },
    { path: '/carfix_cashflow',name:'carfix_cashflow', component: require('./views/CarfixCashflow.vue').default },
    
    { path: '/checkout_registration',name:'checkoutregistration', component: require('./views/Checkout.vue').default },
    { path: '/jobschedular', component: require('./views/JobScheduler.vue').default },
    { path: '/users', name: 'users', component: require('./views/Users.vue').default },
    { path: '/Employees', name: 'mechanics', component: require('./views/Mechanics.vue').default },
    { path: '/transaction_types', name: 'transaction_types', component: require('./views/TransactionTypes.vue').default },
    { path: '/administrators', name: 'administrators', component: require('./views/Administrators.vue').default },
    { path: '/customers', name: 'customers', component: require('./views/Customers.vue').default },
    { path: '/jobs', name: 'jobs', component: require('./views/Jobs.vue').default },
    { path: '/tasks', name: 'tasks', component: require('./views/Tasks.vue').default },
    { path: '/closed-tasks', name: 'closed_tasks', component: require('./views/ClosedTasks.vue').default },
    {
        name: 'edit',
        path: "/task/:id",
        component: require("./views/EditTask.vue").default
    },
    {
        name: 'edit',
        path: "/user/:id",
        component: require("./views/EditUser.vue").default
    },
    {
        name: 'edit_job',
        path: "/job/:id",
        component: require("./views/EditJob.vue").default
    },
    {
        name: 'edit_customer',
        path: "/customer/:id",
        component: require("./views/EditCustomer.vue").default
    },
    {
        name: 'job-scheduler',
        path: "/job-scheduler",
        component: require("./views/JobScheduler.vue").default
    },
    {
        name: 'dashboard',
        path: "/dashboard",
        component: require("./views/Dashboard.vue").default
    },
    {
        name: 'checkout',
        path: "/checkout_registration",
        component: require("./views/Checkout.vue").default
    },
    {
        name: 'edit_sale',
        path: "/sale/:id",
        component: require("./views/EditSale.vue").default
    },


];