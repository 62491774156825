export const menuItems = [
    {
        id: 1,
        label: 'Cash Registration',
        admin:0,
        icon: 'bx-home-circle',
        subItems: [
            {
                id: 2,
                label: 'Daily Transactions',
                link: '/',
                parentId: 1,
                admin:0
            },
            {
                id: 7,
                label: 'Dashboard',
                link: '/carfix_cashflow',
                parentId: 1,
                admin:1
            },
            
        ]
    },
    {
        id: 6,
        label: 'Maintenance',
        admin:1,
        icon: 'bx-user',
        isUiElement: true,
        subItems: [
            {
                id: 7,
                label: 'Users',
                link: '/users',
                parentId: 6,
                admin:1,
            },
            {
                id: 7,
                label: 'Employees',
                link: '/employees',
                parentId: 6,
                admin:1,
            },
            {
                id: 7,
                label: 'Transaction Types',
                link: '/transaction_types',
                parentId: 6,
                admin:1,
            },

        ]
    },
    {
        id: 8,
        label: 'Tasks',
        admin:0,
        icon: 'bx-task',
        isUiElement: true,
        subItems: [
            {
                id: 9,
                label: 'Tasks overview',
                link: '/tasks',
                parentId: 8,
                admin:0,
            },
            {
                id: 9,
                label: 'Closed Tasks',
                link: '/closed-tasks',
                parentId: 8,
                admin:0,
            },
        ]
    },
 
];

