<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import moment from "moment";

/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader, pagination },
  data() {
    return {
      taskData: {
        type: Object,
        default: null,
      },
      title: "Tasks",
      search: "",

      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Tasks",
          active: true,
        },
      ],
      timeHours: [
        {
          label: "7",
          index: "AM",
        },
        {
          label: "8",
          index: "AM",
        },
        {
          label: "9",
          index: "AM",
        },
        {
          label: "10",
          index: "AM",
        },
        {
          label: "11",
          index: "AM",
        },
        {
          label: "12",
          index: "PM",
        },
        {
          label: "1",
          index: "PM",
        },
        {
          label: "2",
          index: "PM",
        },
        {
          label: "3",
          index: "PM",
        },
        {
          label: "4",
          index: "PM",
        },
        {
          label: "5",
          index: "PM",
        },
        {
          label: "6",
          index: "PM",
        },
      ],
      timeMinutes: [
        {
          label: "00",
        },
        {
          label: "15",
        },
        {
          label: "30",
        },
        {
          label: "45",
        },
      ],

      showModal: false,
      submitted: false,
      employees: [],
      task: {
        task_name: "",
        task_description: "",
        due_date: "",
        assigned_to: "",
        notes: "",
        time_in_hours:"",
        time_in_minutes:"",
        time_period:"",
      },
      multipleFiles: [],
    };
  },
  validations: {
    task: {
      task_name: { required },
      task_description: { required },
      due_date: { required },
      assigned_to: { required },
      notes: { required },
      time_in_hours: { required },
      time_in_minutes: { required },
    },
  },
  methods: {
    formateDate(date_vlaue) {
      var date = moment(date_vlaue, "YYYY-MM-DD");
      let date_formated = date.format("DD-MM-YYYY");

      return date_formated;
    },
    async getResults(page = 1) {
      axios
        .get(`/api/closed-tasks?page=${page}&search=${this.search}`)
        .then(({ data }) => {
          this.taskData = data;
        });
    },
    deleteTask(task_id) {
      let n = confirm("are you sure you want to delete this Task?");
      if (n) {
        axios.delete("/api/delete/task/" + task_id).then((response) => {
         alert(response.data.message);
          this.getResults();
        });
      }
    },
  
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars

  },
  mounted() {
    this.getResults();
  },
  created() {
    //this.getResults();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control"
                      v-on:keyup.enter="getResults()"
                      v-model="search"
                      placeholder="Search..."
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date Created</th>
                    <th>Task Name</th>
                    <th>Task Description</th>
                    <th>Due Date</th>
                    <th>Assign To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tasks in taskData.data" :key="tasks.id">
                    <td>
                      <div class="form-check font-size-16">
                        <label
                          class="form-check-label"
                          :for="`customCheck${tasks.id}`"
                          >&nbsp;</label
                        >
                      </div>
                    </td>
                    <td>{{ formateDate(tasks.date_created) }}</td>
                    <td>
                      <p class="mb-0">{{ tasks.task_name }}</p>
                    </td>
                    <td>
                      {{ tasks.task_description }}
                    </td>
                    <td>{{ formateDate(tasks.due_date) +'  '+ tasks.time_in_hours+':'+tasks.time_in_minutes+' '+tasks.time_period }}</td>
                    <td>{{ tasks.assigned_to }}</td>
                    <td>
                      <div class="text-center">
                        <span class=" badge rounded-pill font-size-11  " :class="tasks.status=='Open' ? 'badge-soft-success' : 'badge-soft-danger'"  >{{ tasks.status }}</span  >
                      </div>
                    </td>
                    <td>
                     
                      &nbsp;&nbsp;&nbsp;
                      <a href="javascript:void(0)" @click="deleteTask(tasks.id)"
                        ><i class="fas fa-trash-alt text-danger me-1"></i>
                        Delete</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              align="center"
              :data="taskData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
