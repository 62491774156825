<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import moment from "moment";

/**
 * Customers component
 */
export default {
  components: { Layout, PageHeader, pagination },
  data() {
    return {
      taskData: {
        type: Object,
        default: null,
      },
      title: "Tasks",
      search: "",

      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Tasks",
          active: true,
        },
      ],
      timeHours: [
        {
          label: "7",
          index: "AM",
        },
        {
          label: "8",
          index: "AM",
        },
        {
          label: "9",
          index: "AM",
        },
        {
          label: "10",
          index: "AM",
        },
        {
          label: "11",
          index: "AM",
        },
        {
          label: "12",
          index: "PM",
        },
        {
          label: "1",
          index: "PM",
        },
        {
          label: "2",
          index: "PM",
        },
        {
          label: "3",
          index: "PM",
        },
        {
          label: "4",
          index: "PM",
        },
        {
          label: "5",
          index: "PM",
        },
        {
          label: "6",
          index: "PM",
        },
      ],
      timeMinutes: [
        {
          label: "00",
        },
        {
          label: "15",
        },
        {
          label: "30",
        },
        {
          label: "45",
        },
      ],

      showModal: false,
      submitted: false,
      employees: [],
      task: {
        task_name: "",
        task_description: "",
        due_date: "",
        assigned_to: null,
        notes: "",
        time_in_hours:"",
        time_in_minutes:"",
        time_period:"",
      },
      multipleFiles: [],
    };
  },
  validations: {
    task: {
      task_name: { required },
      task_description: { required },
      due_date: { required },
      assigned_to: { required },
      time_in_hours: { required },
      time_in_minutes: { required },
    },
  },
  methods: {
    formateDate(date_vlaue) {
      var date = moment(date_vlaue, "YYYY-MM-DD");
      let date_formated = date.format("DD-MM-YYYY");

      return date_formated;
    },
    async getEmployees() {
      axios.get(`/api/get/employees`).then((response) => {
        this.employees = response.data;
      });
    },
    edit_task_url(id) {
      return "/task/" + id;
    },

    async getResults(page = 1) {
      axios
        .get(`/api/tasks?page=${page}&search=${this.search}`)
        .then(({ data }) => {
          this.taskData = data;
        });
    },
    deleteTask(task_id) {
      let n = confirm("are you sure you want to delete this Task?");
      if (n) {
        axios.delete("/api/delete/task/" + task_id).then((response) => {
        //  alert(response.data.message);
          this.getResults();
        });
      }
    },
    handleFileUpload(){
        this.multipleFiles = this.$refs.file.files;
      
        console.log(this.multipleFiles);
      },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      console.log("form submited");
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      console.log(this.$v.$invalid);

      if (this.$v.$invalid) {
        return;
      } else {
        this.showModal = false;
        let formData = new FormData();
        for (const i of Object.keys(this.multipleFiles)) {
            formData.append('file[]', this.multipleFiles[i])
          }
        // formData.append('file', this.multipleFiles);
        formData.append('task_name', this.task.task_name);
        formData.append('task_description', this.task.task_description);
        formData.append('due_date', this.task.due_date);
        formData.append('assigned_to', this.task.assigned_to);
        formData.append('notes', this.task.notes);
        formData.append('time_in_hours', this.task.time_in_hours);
        formData.append('time_period', this.task.time_period);
        formData.append('time_in_minutes', this.task.time_in_minutes);
        formData.append('user', this.$gate.user.name);
        formData.append('user_id', this.$gate.user.id);
        // console.log(formData.data); return false;
        axios
          .post("/api/save/task",
           formData,
           {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              })
          .then((response) => {
            this.showForm = false;
            this.getResults();

            this.task = {
              task_name: "",
              task_description: "",
              due_date: "",
              assigned_to: "",
              notes: "",
              time_in_hours: "",
              time_in_minutes: "",
              time_period: "",
            };
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.errors = [];
              _.each(err.response.data.errors, (error) => {
                _.each(error, (e) => {
                  this.errors.push(e);
                });
              });
            }
          });
      }
      this.submitted = false;
    },
  },
  mounted() {
    this.getResults();
    this.getEmployees();
  },
  created() {
    //this.getResults();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input
                      type="text"
                      class="form-control"
                      v-on:keyup.enter="getResults()"
                      v-model="search"
                      placeholder="Search..."
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <button
                    type="button"
                    class="btn btn-success btn-rounded mb-2 me-2"
                    @click="showModal = true"
                  >
                    <i class="mdi mdi-plus me-1"></i> Add New Task
                  </button>

                  <b-modal
                    v-model="showModal"
                    title="Add New Task"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                    <form @submit.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="task_name">Task Name</label>
                            <input
                              id="task_name"
                              v-model="task.task_name"
                              type="text"
                              class="form-control"
                              placeholder="Task name"
                              :class="{
                                'is-invalid':
                                  submitted && $v.task.task_name.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.task.task_name.required"
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="task_description"
                              >Task Description</label
                            >
                            <textarea
                              id="task_description"
                              v-model="task.task_description"
                              class="form-control"
                              placeholder="Task description"
                              :class="{
                                'is-invalid':
                                  submitted && $v.task.task_description.$error,
                              }"
                            ></textarea>

                            <div
                              v-if="
                                submitted && !$v.task.task_description.required
                              "
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-3">
                            <label for="due_date">Due Date</label>
                            <input
                              id="due_date"
                              v-model="task.due_date"
                              type="date"
                              class="form-control"
                              placeholder="Due Date"
                              :class="{
                                'is-invalid':
                                  submitted && $v.task.due_date.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.task.due_date.required"
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="mb-3">
                            <label for="time_in_hours">Time hrs</label>
                            <select
                              class="form-select time_in_hours"
                              v-model="task.time_in_hours"
                                :class="{'is-invalid':  submitted && $v.task.time_in_hours.$error }"
                            >
                              <template v-for="(item, index) in timeHours">
                                <option :value="item.label+'-'+item.index" :key="index">
                                  {{ item.label + " " + item.index }}
                                </option>
                              </template>
                            </select>
                            <div
                              v-if="
                                submitted && !$v.task.time_in_hours.required
                              "
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="mb-3">
                            <label for="time_in_minutes">Time min.</label>
                            <select
                              class="form-select time_in_minutes"
                              v-model="task.time_in_minutes"
                               :class="{'is-invalid':  submitted && $v.task.time_in_minutes.$error }"
                            >
                              <template v-for="(item, index) in timeMinutes">
                                <option :value="item.label" :key="index">
                                  {{ item.label}}
                                </option>
                              </template>
                            </select>
                            <div
                              v-if="
                                submitted && !$v.task.time_in_minutes.required
                              "
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="assigned_to">Assign to</label>
                       <select class="form-select assigned_to" v-model="task.assigned_to" :class="{'is-invalid':  submitted && $v.task.assigned_to.$error }"
                            >
                              <option>Select Option</option>
                              <template v-for="(m, index) in employees">
                                <option :value="m" :key="index">{{ m }}</option>
                              </template>
                            </select>
                            <div
                              v-if="submitted && !$v.task.assigned_to.required"
                              class="invalid-feedback"
                            >
                              This value is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="task_description">Notes</label>
                            <textarea
                              id="notes"
                              v-model="task.notes"
                              class="form-control"
                              placeholder="Task Notes"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-start pt-5 mt-3">
                           <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" multiple/>
                        </div>
                        <div class="col text-end pt-5 mt-3">
                          <b-button variant="light" @click="showModal = false"
                            >Cancel</b-button
                          >
                          <b-button type="submit" variant="success" class="ms-1"
                            >Create Task</b-button
                          >
                        </div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date Created</th>
                    <th>Task Name</th>
                    <th>Task Description</th>
                    <th>Due Date</th>
                    <th>Assign To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tasks in taskData.data" :key="tasks.id">
                    <td>
                      <div class="form-check font-size-16">
                        <label
                          class="form-check-label"
                          :for="`customCheck${tasks.id}`"
                          >&nbsp;</label
                        >
                      </div>
                    </td>
                    <td>{{ formateDate(tasks.date_created) }}</td>
                    <td>
                      <p class="mb-0">{{ tasks.task_name }}</p>
                    </td>
                    <td>
                      {{ tasks.task_description }}
                    </td>
                    <td>{{ formateDate(tasks.due_date) +'  '+ tasks.time_in_hours+':'+tasks.time_in_minutes+' '+tasks.time_period }}</td>
                    <td>{{ tasks.assigned_to }}</td>
                    <td>
                      <div class="text-center" v-if="tasks.status!='Completed'">
                        <span class=" badge rounded-pill font-size-11  " :class="tasks.status=='Open' ? 'badge-soft-success' : 'badge-soft-danger'"  >{{ tasks.status }}</span  >
                      </div>
                      <div class="text-center" v-else>
                        <span class=" badge rounded-pill font-size-11  badge-soft-primary "  >{{ tasks.status }}</span  >
                      </div>
                    </td>
                    <td>
                      <router-link :to="edit_task_url(tasks.id)" class="mr-3">
                        <i class="fas fa-pencil-alt text-success me-1"></i> Edit
                      </router-link>
                      &nbsp;&nbsp;&nbsp;
                      
                      <a href="javascript:void(0) "  v-if="$gate.isAdmin()"  @click="deleteTask(tasks.id)"
                        ><i class="fas fa-trash-alt text-danger me-1"></i>
                        Delete</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              align="center"
              :data="taskData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
