<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-xl-4">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <div class="form-group mb-3">
                  <div class="col-xl-8 offset-xl-2">
                    Select Date To View Cash Registration
                  </div>
                  <div class="col-xl-8 offset-xl-2">
                    
                    <!-- <input
                      type="date"
                      class="form-control"
                      @change="updateLegder($event)"
                    /> -->

                    <div
                      class="col-sm-2"
                      style="display: inline-block;"
                    >
                      <div class="search-box me-2 mb-2 d-inline-block">
                        <div class="position-relative">
                          <i
                            class="bx bx-left-arrow-alt search-icon"
                            @click="previousDay()"
                          ></i>
                          <input
                            type="text"
                            class="form-control"
                            v-model="currentLedgerDate"
                            readonly
                            style="
                              background-color: #fff;
                              padding-left: 0px;
                              text-align: center;
                              width: 190px;
                            "
                          />
                          <i
                            class="bx bx-right-arrow-alt search-icon"
                            @click="comingDay()"
                            style="left: 165px"
                          ></i>
                        </div>
                      </div>
                    </div>      

                  </div>
                </div>

                <address>
                  <p>
                    <span
                      ><strong>Date:</strong>
                      <h6 class="float-end">{{ this.todayDate }}</h6></span
                    >
                  </p>
                  <p>
                    <span
                      ><strong>Coworker Name:</strong>
                      <h6 class="float-end">
                        {{ this.yesterdayClosedBy }}
                      </h6></span
                    ><br />
                  </p>
                </address>

                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="custom_table">
                      <tr class="table-dark">
                        <th colspan="4">Opening Balance</th>
                        <th>Time: {{ yesterdayClosedTime }}</th>
                      </tr>
                      <tr class="text-center">
                        <th>Currency on Hand</th>
                        <th></th>
                        <th>Quantity</th>
                        <th></th>
                        <th>Total in ANG</th>
                      </tr>
                    </thead>
                    <tbody class="text-end custom_table">
                      <tr
                        v-for="(item, index) in previousCurrency"
                        :key="index"
                      >
                        <td>{{ item.label }}</td>
                        <td>x</td>
                        <td>
                          {{ item.qty }}
                        </td>
                        <td>=</td>
                        <td>{{ item.total }}</td>
                      </tr>
                    </tbody>
                    <tfoot class="text-end">
                      <tr>
                        <td colspan="4">Total Cash on hand at day opening</td>
                        <td>
                          <strong>ANG</strong>
                          <span>{{ yesterday_total_amount }}</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <address>
                <p>
                  <span
                    ><strong>Date:</strong>
                    <h6 class="float-end">{{ this.todayDate }}</h6></span
                  >
                </p>
                <p>
                  <span
                    ><strong>Coworker Name:</strong>
                    <h6 class="float-end">{{ this.$gate.userName() }}</h6></span
                  ><br />
                </p>
              </address>

              <div class="table-responsive">
                <table class="table align-middle mb-0">
                  <thead class="custom_table">
                    <tr class="table-dark">
                      <th colspan="5">Closing Balance</th>
                      <th width="28%">Time: {{ currentTime }}</th>
                    </tr>
                    <tr class="text-center">
                      <th>Currency</th>
                      <th></th>
                      <th>Quantity</th>
                      <th></th>
                      <th>Total in ANG</th>
                      <th>To Vault</th>
                    </tr>
                  </thead>
                  <tbody class="text-end custom_table closing_table">
                    <tr v-for="(item, index) in newCurrency" :key="index">
                      <td>{{ item.label }}</td>
                      <td>x</td>
                      <td>
                        <input
                          min="0"
                          type="number"
                          :data-currency="50"
                          v-model="item.qty"
                          @change="calculatetotal(index)"
                          :readonly="!validateTodayInput()"
                        />
                      </td>
                      <td>=</td>
                      <td>{{ item.total }}</td>
                      <td>
                        <input
                          min="0"
                          type="number"
                          :data-currency="50"
                          v-model="item.toVault"
                          @change="calculatetotal(index)"
                          :readonly="!validateTodayInput()"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="text-end">
                    <tr>
                      <td colspan="5">Total cash on hand at day closing</td>
                      <td>
                        <strong>ANG</strong> <span>{{ total_amount }}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="row mt-2 safe_amounts">
                <div class="col-md-6 text-start">Amount to safe</div>
                <div class="col-md-6 text-end">
                  <input
                    type="number"
                    class="form-control daily_trans"
                    size="2"
                    v-model="amountTosafe"
                    @change="UpdateAmountToSafe()"
                  />
                </div>
                <div class="col-md-6 text-start">Amount from safe</div>
                <div class="col-md-6 text-end">
                  <input
                    type="number"
                    class="form-control daily_trans"
                    @change="UpdateAmountFromSafe()"
                    size="2"
                    v-model="amountFromsafe"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="card" style="height: 55%">
          <div
            class="card-body"
            style="overflow-y: auto; flex-direction: column-reverse"
          >
            <div class="table-responsive">
              <table class="table table_custom mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th width="15%">Date</th>
                    <th width="35%">Description</th>
                    <th width="20%">Transaction Type</th>
                    <th>Amount</th>
                    <th width="20%">Employee</th>
                    <th v-if="$gate.isAdmin()">User</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="text-center" id="tableBody">
                  <tr v-for="(sales, index) in dailySales.data" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ sales.date }}</td>
                    <td style="text-align:left;">{{ sales.description }}</td>
                    <td>{{ sales.transaction_type }}</td>
                    <td>{{ sales.amount }}</td>
                    <td>{{ sales.employee }}</td>
                    <td v-if="$gate.isAdmin()">{{ sales.user }}</td>
                    <td v-if="validateTodayInput()">
                      <router-link :to="eidt_sale(sales.id)" class="mr-3">
                        <i class="fas fa-pencil-alt text-success me-1"></i>
                      </router-link>
                      <a href="javascript:void(0)" @click="deleteSale(sales.id)"
                        ><i class="fas fa-trash-alt text-danger me-1"></i
                      ></a>
                    </td>
                  </tr>

                  <tr v-if="validateTodayInput()">
                    <th scope="row"></th>
                    <td>
                      <input
                        v-model="sale.date"
                        type="text"
                        readonly="readonly"
                        class="form-control daily_trans"
                      />
                    </td>
                    <td>
                      <input
                        v-model="sale.description"
                        type="text"
                        class="form-control daily_trans"
                      />
                    </td>
                    <td>
                      <select
                        class="form-select daily_trans"
                        v-model="sale.type"
                      >
                        <option value="">Transaction Type</option>
                        <template v-for="(tt, index) in transactionTypes">
                          <option :value="tt" :key="index">{{ tt }}</option>
                        </template>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        v-model="sale.amount"
                        class="form-control daily_trans"
                        style="width: 120px"
                      />
                    </td>
                    <td>
                      <select
                        class="form-select daily_trans"
                        v-model="sale.employee"
                      >
                        <option value="">Select Option</option>
                        <template v-for="(m, index) in employees">
                          <option :value="m" :key="index">{{ m }}</option>
                        </template>
                      </select>
                    </td>
                    <td>
                      <a href="javascript:void(0)" @click="createItem()"
                        ><i
                          id="saveItem"
                          class="fas fa-save text-success me-1 fs-3 lh-2"
                        ></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-6 offset-xl-6">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col justify-start">Total Reconciled petty Cash</div>
                <div class="col justify-end text-end">ANG {{ pettyCash }}</div>
              </div>
              <div class="row mb-3">
                <div class="col justify-start">
                  Difference
                 
                    <span v-if="difference != 0"  class="badge bg-danger rounded-pill"><i class="fas fa-exclamation"></i></span>
          
                  
                   <span v-else-if="difference == 0" class="badge bg-success rounded-pill"><i class="fas fa-check"></i></span>
                  
                </div>
                <div class="col-4 justify-end text-end">ANG {{ difference }}</div>
              </div>
              <div class="row">
                <div class="col justify-start">Comment</div>
                <div class="col">
                  <textarea
                    name=""
                    id=""
                    cols="40"
                    rows="4"
                    class="form-control"
                    v-model="comment"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 offset-xl-6">
          <a type="button" class="btn btn-success">Cancel</a>
          <a class="btn btn-success" @click="saveClosingBalance()">Save</a>
        </div>
      </div>

      <div class="row" v-if="$gate.isAdmin()">
        <div class="card">
          <div class="card-heading">
            <div class="card-body">
              <h4 class="card-title">User Activity Log</h4>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr class="heading_tr">
                      <th>#</th>
                      <th>Date</th>
                      <th>Details</th>
                      <th>User name</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr class="body_tr" v-for="(logs, index) in dailyLogs.data" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ logs.date }}</td>
                      <td v-html="logs.details"></td>
                      <td>{{ logs.user_name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
th {
  text-align: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>>
</style>
<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import simplebar from "simplebar-vue";

export default {
  components: { Layout, PageHeader, simplebar },
  data() {
    return {
      totalinANG: "0.00",
      openingbalance: [],
      closingBalance: [],
      dailySales: [],
      dailyLogs: [],
      styleObject: {
        top: "",
        left: "",
      },
      inputDatePicker: "",
      yesterdayClosedBy: "",
      yesterdayClosedTime: "",
      currentLedgerDate: moment().format("YYYY-MM-DD"),
      currentTime: moment().format("hh:mm A"),
      todayDate: moment(this.currentLedgerDate).format("dddd , MMMM DD , yyyy"),
      yesterdayDate: moment()
        .subtract(1, "days")
        .format("dddd , MMMM DD , yyyy"),

      employees: [],
      transactionTypes: [],
      title: "DASHBOARD",
      items: [
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "DASHBOARD",
          active: true,
        },
      ],
      sale: {
        date: moment().format("DD-MMM-YY"),
        description: "",
        type: "",
        amount: "",
        employee: "",
        user: this.$gate.user.name,
      },
      previousCurrency: [
        {
          currency: "$",
          label_value: "50.00",
          label: "$50.00",
          qty: 0,
          total: 0,
        },
        {
          label_value: "20.00",
          label: "$20.00",
          qty: 0,
          total: 0,
          currency: "$",
        },
        {
          label_value: "1.00",
          label: "$1.00",
          qty: 0,
          total: 0,
          currency: "$",
        },
        {
          label_value: "0.01",
          label: "ANG 0.01",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "0.05",
          label: "ANG 0.05",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "0.10",
          label: "ANG 0.10",
          qty: 0,
          currency: "ANG",
          total: 0,
        },
        {
          label_value: "0.25",
          label: "ANG 0.25",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "1.0",
          label: "ANG 1.0",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "5.0",
          label: "ANG 5.0",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "10.0",
          label: "ANG 10.0",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "25.0",
          label: "ANG 25.0",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "50.0",
          label: "ANG 50.0",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
        {
          label_value: "100.0",
          label: "ANG 100.0",
          qty: 0,
          total: 0,
          currency: "ANG",
        },
      ],
      newCurrency: [
        {
          currency: "$",
          label_value: "50.00",
          label: "$50.00",
          qty: 0,
          total: 0,
          toVault: 0,
        },
        {
          label_value: "20.00",
          label: "$20.00",
          qty: 0,
          total: 0,
          currency: "$",
          toVault: 0,
        },
        {
          label_value: "1.00",
          label: "$1.00",
          qty: 0,
          total: 0,
          currency: "$",
          toVault: 0,
        },
        {
          label_value: "0.01",
          label: "ANG 0.01",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "0.05",
          label: "ANG 0.05",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "0.10",
          label: "ANG 0.10",
          qty: 0,
          currency: "ANG",
          total: 0,
          toVault: 0,
        },
        {
          label_value: "0.25",
          label: "ANG 0.25",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "1.0",
          label: "ANG 1.0",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "5.0",
          label: "ANG 5.0",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "10.0",
          label: "ANG 10.0",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "25.0",
          label: "ANG 25.0",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "50.0",
          label: "ANG 50.0",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
        {
          label_value: "100.0",
          label: "ANG 100.0",
          qty: 0,
          total: 0,
          currency: "ANG",
          toVault: 0,
        },
      ],
      currentActiveDatepk:0,
      total_amount: 0.00,
      yesterday_total_amount: 0,
      angamount: parseFloat(0),
      comment: "",
      amountFromsafe: "0.00",
      amountTosafe: "0.00",
      difference: "0.00",
      pettyCash: "",
      cashExpenses: "0.00",
      cashReceipts: "0.00",
      data: [],
    };
  },
  methods: {

    previousDay() {
      this.currentActiveDatepk = this.currentActiveDatepk + 1;
      this.dailyDates();
    },
    comingDay() {
      this.currentActiveDatepk = this.currentActiveDatepk - 1;
      this.dailyDates();
    },
    dailyDates() {
      var currentDate = moment().subtract(this.currentActiveDatepk, "days").format("YYYY-MM-DD");
      this.updateLegder(currentDate);
    },
    updateLegder(currentDate) {
      this.currentLedgerDate = currentDate;
      this.sale.date = currentDate;
      console.log(currentDate);
      //console.log(e.target.value);
      this.get_opening_balance();
      this.get_current_balance();
      this.get_sales();
      this.get_logs();
      this.todayDate = moment(this.currentLedgerDate).format(
        "dddd , MMMM DD , yyyy"
      );
    },
    addActivityLog(value, type) {
      let data = {
        inputFieldValue: value,
        user: window.user,
        date: this.currentLedgerDate,
        type: type,
      };
      axios.post("/api/save-acivity-log", data).then((response) => {
        this.get_log();
      });
    },
    UpdateAmountFromSafe() {
      this.pettyCash =
        parseFloat(this.total_amount) + parseFloat(this.amountFromsafe);
      this.addActivityLog(this.amountFromsafe, "amount_from_safe");
      this.saveClosingBalance();
    },
    UpdateAmountToSafe() {
      this.addActivityLog(this.amountTosafe, "amount_to_safe");
      this.saveClosingBalance();
    },
    validateTodayInput() {
      if (
        this.currentLedgerDate == moment().format("YYYY-MM-DD") ||
        this.$gate.isAdmin()
      ) {
        return true;
      } else {
        return false;
      }
    },
    saveClosingBalance() {
      let data = {
        newCurrency: this.newCurrency,
        comment: this.comment,
        user: window.user,
        amountFromsafe: this.amountFromsafe,
        amountTosafe: this.amountTosafe,
        date: this.currentLedgerDate,
        difference: this.difference,
        openingBalance: this.yesterday_total_amount,
      };

      axios.post("/api/save-closingbalance", data).then((response) => {
        alert(response.data.message);
      });
    },
    calculatetotal(i) {
      var currency = this.newCurrency[i].label_value;
      var qty = this.newCurrency[i].qty;
      var vualt = this.newCurrency[i].toVault;
      let total_ang = 0;
      let total_tovault = 0;
      if (this.newCurrency[i].currency == "$") {
        total_ang = parseFloat(currency * (qty - vualt) * 1.75);
      } else {
        total_ang = parseFloat(currency * (qty - vualt));
      }
      total_tovault = currency * vualt;
      this.angamount = 0;
      this.newCurrency[i].total = parseFloat(total_ang).toFixed(2);

      if (this.amountFromsafe == undefined) this.amountFromsafe = 0.0;
      this.amountTosafe = 0.0;
      for (let j = 0; j < this.newCurrency.length; j++) {
        this.angamount += parseFloat(this.newCurrency[j].total);
        this.amountTosafe =
          parseFloat(this.amountTosafe) +
          parseFloat(
            parseFloat(this.newCurrency[j].label_value) *
              parseFloat(this.newCurrency[j].toVault)
          );
      }
      this.total_amount = parseFloat(this.angamount).toFixed(2);
      this.pettyCash = parseFloat(
        parseFloat(this.amountFromsafe) + parseFloat(this.angamount)
      ).toFixed(2);
      this.calculateDifference();
    },
    calculateDifference(){
      console.log('total Yesterday '+ this.yesterday_total_amount + 
                  ' '+this.total_amount + '-- ' 
                  + this.cashReceipts + ' -- ' 
                  + this.cashExpenses + ' -- ' 
                  + this.amountTosafe + ' -- ' 
                  +  this.amountFromsafe);
      var dif_rence =
      
        parseFloat(this.yesterday_total_amount)
        + parseFloat(this.cashReceipts)
        - parseFloat(this.cashExpenses)
        - parseFloat(this.amountTosafe)
        + parseFloat(this.amountFromsafe)
        - parseFloat(this.total_amount);
        
      this.difference = parseFloat(dif_rence).toFixed(2);

      let data = {
        'date' : this.currentLedgerDate,
        'difference':this.difference
      };

      axios.post("/api/update-difference", data).then((response) => {
      });

    },
    eidt_sale(id) {
      return "/sale/" + id;
    },
    deleteSale(sale_id) {
      let n = confirm("are you sure you want to delete the user?");
      if (n) {
        axios.delete("/api/delete/sale/" + sale_id).then((response) => {
          alert(response.data.message);
          this.get_sales();
        });
      }
    },
    async get_opening_balance() {
      axios
        .get(`/api/opening_balance?currentLedgerDate=` + this.currentLedgerDate)
        .then((response) => {
          this.openingbalance = response.data;
          this.previousCurrency = response.data.currencydata;
          this.yesterday_total_amount = response.data.opening_balance.total;
          this.yesterdayClosedBy = response.data.opening_balance.closed_by;
          this.yesterdayClosedTime = response.data.closing_time;
          this.yesterdayDate = moment(response.data.last_working_day).format(
            "dddd , MMMM DD , yyyy"
          );
          this.calculateDifference();
        });
      // console.log(this.openingbalance);
    },
    async get_current_balance() {
      axios
        .get(`/api/current_balance?currentLedgerDate=` + this.currentLedgerDate)
        .then((response) => {
          
          this.pettyCash = response.data.opening_balance.total;
         

          this.amountFromsafe = response.data.opening_balance.amount_from_safe;
         
          this.amountTosafe = response.data.opening_balance.amount_to_safe;
        
          //this.difference = response.data.opening_balance.difference;
         
          this.total_amount = parseFloat( parseFloat(this.pettyCash) - parseFloat(this.amountFromsafe)).toFixed(2);

          this.calculateDifference();

       
          if (typeof response.data.currencydata === "object") {
            this.newCurrency = response.data.currencydata;
          } else {
            this.newCurrency = [
              {
                currency: "$",
                label_value: "50.00",
                label: "$50.00",
                qty: 0,
                total: 0,
                toVault: 0,
              },
              {
                label_value: "20.00",
                label: "$20.00",
                qty: 0,
                total: 0,
                currency: "$",
                toVault: 0,
              },
              {
                label_value: "1.00",
                label: "$1.00",
                qty: 0,
                total: 0,
                currency: "$",
                toVault: 0,
              },
              {
                label_value: "0.01",
                label: "ANG 0.01",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "0.05",
                label: "ANG 0.05",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "0.10",
                label: "ANG 0.10",
                qty: 0,
                currency: "ANG",
                total: 0,
                toVault: 0,
              },
              {
                label_value: "0.25",
                label: "ANG 0.25",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "1.0",
                label: "ANG 1.0",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "5.0",
                label: "ANG 5.0",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "10.0",
                label: "ANG 10.0",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "25.0",
                label: "ANG 25.0",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "50.0",
                label: "ANG 50.0",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
              {
                label_value: "100.0",
                label: "ANG 100.0",
                qty: 0,
                total: 0,
                currency: "ANG",
                toVault: 0,
              },
            ];
          }
        });
    },
    async get_sales() {
      axios
        .get(`/api/get_sales?currentLedgerDate=` + this.currentLedgerDate)
        .then((response) => {
          this.dailySales = Array();
          this.dailySales = response;

          let dail_sales = this.dailySales.data;
          this.cashExpenses = 0;
          this.cashReceipts = 0;
          for (let k = 0; k < dail_sales.length; k++) {
            if (dail_sales[k].transaction_type == "Cash Expenses") {
              this.cashExpenses =
                parseFloat(this.cashExpenses) +
                parseFloat(dail_sales[k].amount);
            }
            if (dail_sales[k].transaction_type == "Cash Receipts") {
              this.cashReceipts =
                parseFloat(this.cashReceipts) +
                parseFloat(dail_sales[k].amount);
            }
          }
          this.calculateDifference();

        });
       console.log("cash expenses" + this.cashExpenses);
        console.log("cash expenses" + this.cashReceipts);
    },
    async get_logs() {
      axios
        .get(`/api/get_logs?currentLedgerDate=` + this.currentLedgerDate)
        .then((response) => {
          this.dailyLogs = Array();
          this.dailyLogs = response;

          // let dail_sales = this.dailySales.data;
          // for (let k = 0; k < dail_sales.length; k++) {
          //   if (dail_sales[k].transaction_type == "Cash Expenses") {
          //     this.cashExpenses =
          //       parseFloat(this.cashExpenses) +
          //       parseFloat(dail_sales[k].amount);
          //   }
          // }
        });
    },
    async getEmployees() {
      axios.get(`/api/get/employees`).then((response) => {
        this.employees = response.data;
      });
    },
    async getTransactionTypes() {
      axios.get(`/api/get/transaction_types`).then((response) => {
        this.transactionTypes = response.data;
      });
    },
    createItem: function () {
      console.log(this.sale);
      if (
        this.sale.description == "" ||
        this.sale.type == "" ||
        this.sale.amount == "" ||
        this.sale.employee == ""
      ) {
        alert("Please Fill The Transaction Details Properly!");
        return;
      }
      this.sale.date = this.currentLedgerDate;
      let data = {};
      Object.assign(data, this.sale);
      this.sale.description = "";
      this.sale.type = "";
      this.sale.amount = "";
      this.sale.employee = "";
      axios.post("/api/save-sales", data).then((response) => {
        this.get_sales();
        this.saveClosingBalance();
      });
    },
  },
  mounted() {
    //  this.cal_cashexpenses();
    this.get_sales();
    this.get_logs();
    this.getEmployees();
    this.getTransactionTypes();
    this.get_opening_balance();
  },
  created() {
    this.get_current_balance();
    // console.log("holaa");
    // console.log("amount from safe" + this.amountFromsafe);
    // console.log("amount to safe" + this.amountTosafe);
  },
};
</script>
<style>
.daily_trans {
  height: 30px;
  padding: 0px 10px;
}
.page-content .container-fluid {
  width: 98% !important;
  max-width: 98% !important;
}
.safe_amounts input {
  width: 150px;
  float: right;
  margin-bottom: 4px;
}
.heading_tr th, .body_tr td{
  text-align: start;
}
</style>